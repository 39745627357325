var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (!_vm.subPermission.store && !_vm.isEdit) || (!_vm.subPermission.update && _vm.isEdit)
  )?_c('content-not-view'):(_vm.isLoading)?_c('loading'):_c('div',{staticClass:"edy-year-add"},[_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"education year Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheNameHere')},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"subjuct","label":_vm.$t('g.subjuct')}},[_c('validation-provider',{attrs:{"name":"subjuct","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('v-select',{staticClass:"edu-years",attrs:{"options":_vm.all_subjects,"state":errors.length > 0 ? false : null,"multiple":"","reduce":function (subject) { return subject.id; },"placeholder":_vm.$t('g.subjuct'),"label":"name","dir":_vm.dir},model:{value:(_vm.form_data.subjects),callback:function ($$v) {_vm.$set(_vm.form_data, "subjects", $$v)},expression:"form_data.subjects"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.image'),"label-for":"image"}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"browse-text":_vm.$t('g.browse'),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.choose_image')},model:{value:(_vm.form_data.image),callback:function ($$v) {_vm.$set(_vm.form_data, "image", $$v)},expression:"form_data.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])}),(_vm.isEdit && _vm.currentImageLink)?_c('a',{attrs:{"target":"_blank","href":_vm.currentImageLink}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("current_image"))+" ")])]):_vm._e()],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.language'),"label-for":"Language"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_data.lang),expression:"form_data.lang"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_data, "lang", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("g.please_select_language"))+" ")]),_vm._l((_vm.lang_options),function(lang,index){return _c('option',{key:index,domProps:{"value":lang.value}},[_vm._v(" "+_vm._s(lang.text)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3340316886)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.status')}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-status",attrs:{"switch":"","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.is_available),callback:function ($$v) {_vm.$set(_vm.form_data, "is_available", $$v)},expression:"form_data.is_available"}},[_vm._v(" "+_vm._s(_vm.form_data.is_available ? _vm.$t("g.show") : _vm.$t("g.hide"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.save") : _vm.$t("g.add"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }