<template>
  <content-not-view
    v-if="
      (!subPermission.store && !isEdit) || (!subPermission.update && isEdit)
    "
  />
  <loading v-else-if="isLoading" />
  <div class="edy-year-add" v-else>
    <b-card :title="$t('g.basicInformations')">
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="education year Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="subjuct" :label="$t('g.subjuct')">
                <validation-provider
                  #default="{ errors }"
                  name="subjuct"
                  rules="required"
                >
                  <v-select
                    v-model="form_data.subjects"
                    :options="all_subjects"
                    :state="errors.length > 0 ? false : null"
                    multiple
                    :reduce="(subject) => subject.id"
                    class="edu-years"
                    :placeholder="$t('g.subjuct')"
                    label="name"
                    :dir="dir"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('g.image')" label-for="image">
                <validation-provider
                  #default="{ errors }"
                  name="image"
                  rules="required"
                >
                  <b-form-file
                    :browse-text="$t('g.browse')"
                    v-model="form_data.image"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.choose_image')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
                <a
                  target="_blank"
                  v-if="isEdit && currentImageLink"
                  :href="currentImageLink"
                >
                  <p>
                    {{ $t("current_image") }}
                  </p>
                </a>
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="isEdit">
              <b-form-group :label="$t('g.language')" label-for="Language">
                <validation-provider
                  #default="{ errors }"
                  name="Language"
                  rules="required"
                >
                  <select class="form-control" v-model="form_data.lang">
                    <option selected value disabled>
                      {{ $t("g.please_select_language") }}
                    </option>
                    <option
                      v-for="(lang, index) in lang_options"
                      :key="index"
                      :value="lang.value"
                    >
                      {{ lang.text }}
                    </option>
                  </select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.status')">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-checkbox
                    switch
                    v-model="form_data.is_available"
                    :state="errors.length > 0 ? false : null"
                    class="custom-status"
                  >
                    {{ form_data.is_available ? $t("g.show") : $t("g.hide") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.save") : $t("g.add") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormFile,
  BSpinner,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
import loading from "@/components/loading/loading.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BSpinner,
    Error,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    contentNotView,
    loading,
  },
  data() {
    return {
      form_data: {
        name: null,
        is_available: false,
        subjects: null,
        lang: null,
        image: null,
      },
      currentImageLink: null,
      lang_options: [
        { value: "ar", text: this.$t("g.arabic") },
        { value: "en", text: this.$t("g.english") },
      ],
      available_options: [
        {
          value: null,
          text: this.$t("g.please_select_status"),
        },
        {
          value: 1,
          text: this.$t("g.available"),
        },
        {
          value: 0,
          text: this.$t("g.notAvailable"),
        },
      ],
      isEdit: null,
      currentId: null,
      all_subjects: [],
      isLoading: true,
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddEducationYear") {
        this.isEdit = false;
        this.clearForm();
      } else if (to.name === "EditEducationYear") {
        this.isEdit = true;
      }
    },
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.currentId = this.$route.params.id;
      this.getEduYear();
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    this.getSubjects();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "edu-years");
      return this.$store.state.userData.sub_Permission;
    },
    dir() {
      return window?.localStorage?.getItem("lang");
    },
  },
  methods: {
    clearForm() {
      for (const i in this.form_data) {
        this.form_data[i] = null;
      }
    },
    getEduYear() {
      this.$http
        .get(`admin/edu-years/${this.currentId}`, {
          headers: {
            "x-locale": localStorage.getItem("lang"),
          },
        })
        .then((res) => {
          for (const key in res.data.data) {
            if (key === "is_available") {
              this.form_data[key] = res.data.data[key];
            } else if (key === "media") {
              this.form_data.image = res.data.data[key];
              this.currentImageLink = res.data.data[key][0].path;
            } else {
              this.form_data[key] = res.data.data[key];
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log(err);
        });
    },
    getSubjects() {
      this.$http
        .get(`admin/subjects?available=true`)
        .then((res) => {
          this.all_subjects.push(...res.data.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log(err);
        });
    },
    submitEdit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item === "subjects") {
              for (const i in this.form_data[item]) {
                formData.append(
                  `subjects[${i}]`,
                  this.form_data[item][i].id
                    ? this.form_data[item][i].id
                    : this.form_data[item][i]
                );
              }
            } else if (item === "image") {
              if (this.form_data[item].$path === "") {
                formData.append(item, this.form_data[item]);
              } else {
                delete this.form_data[item];
              }
            } else {
              formData.append(item, this.form_data[item]);
            }
          }

          this.$http
            .post(`admin/edu-years/${this.currentId}`, formData, {
              params: { _method: "put" },
            })
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                this.$t("g.send.successTitle"),
                this.$t("g.edu_year/edu_year_edited_successfully")
              );
              setTimeout(() => {
                this.$router.push({ name: "EducationYearsList" });
              }, 500);
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              console.log(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.send.invalidInput"),
            this.$t("g.send.invalidInputBody")
          );
        }
      });
    },
    submitNewAdditon() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item === "subjects") {
              for (const i in this.form_data[item]) {
                formData.append(`subjects[${i}]`, this.form_data[item][i]);
              }
            } else {
              formData.append(item, this.form_data[item]);
            }
          }
          this.$http
            .post("admin/edu-years", formData)
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                this.$t("g.send.successTitle"),
                this.$t("g.edu_year/edu_year_added_successfully")
              );
              setTimeout(() => {
                this.$router.push({ name: "EducationYearsList" });
              }, 1000);
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              console.log(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.send.invalidInput"),
            this.$t("g.send.invalidInputBody")
          );
        }
      });
    },
    validationForm() {
      this.form_data.is_available = Number(this.form_data.is_available);
      if (this.isEdit) {
        this.submitEdit();
      } else {
        delete this.form_data.lang;
        this.submitNewAdditon();
      }
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
